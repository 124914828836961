import { child, get, ref } from 'firebase/database';

import { defineStore } from 'pinia';
import { getAuth } from 'firebase/auth';
import appUtils from '../components/utils/appUtils';

//HOW TO USE:
//import { useCategoryStore } from '../../stores/CategoryStore';
//const categoryStore = useCategoryStore();

export const useCategoryStore = defineStore('CategoryStore', {
  state: () => {
    return {
      categories: [],
      counter: 2,
      konto1: [],
      konto2: [],
      konto3: [],
      konto4: [],
      kontos: [],
      allKontos: [],
    };
  },
  getters: {
    count1() {
      return this.categories.length;
    },
    hasKontos() {
      return this.kontos && this.kontos.length > 0;
    },
  },
  actions: {
    add() {
      this.categories.push('a' + this.count1);
    },
    remove() {
      this.categories.pop();
    },

    loadKontosFirebase(payload) {
      let promise = new Promise((resolve, reject) => {
        try {
          // console.log('loadKontosFirebase = > payload');
          // console.log(payload);
          const db = payload.db;
          //const currentUserUid = context.rootGetters.getCurrentUser;

          //const db = getDatabase();
          let userUid = getAuth().currentUser.uid;
          console.log('getAuth().currentUser');
          console.log(getAuth().currentUser);

          get(child(ref(db), `konto/${userUid}`))
            .then((snapshot) => {
              if (snapshot.exists()) {
                //console.log(snapshot.val());

                this.rearrangeKonto(snapshot.val());
              } else {
                //console.log('No data available.');
              }

              resolve();
            })
            .catch((error) => {
              reject(error);
              console.error(error);
            });
        } catch (error) {
          reject(error);
        }
      });

      return promise;
    },

    rearrangeKonto(responseData) {
      let tmpArray = [];
      this.allKontos = responseData;

      let props = [
        'id',
        'iduser',
        'insdtm',
        'insuser',
        'upddtm',
        'upduser',
        'description',
        'lvl',
        'guid_parent',
        'type',
        'active',
        'order',
      ];

      for (const key in responseData) {
        const item1 = {};
        item1.id = key;
        item1.guid = key;
        //console.log(key);
        for (let i = 0; i < props.length; i++) {
          //let prop = props[i];
          item1[props[i]] = responseData[key][props[i]];
          item1['longdesc'] = '';
          item1['counter'] = 0;
        }
        tmpArray.push(item1);
      }

      //replace all null values for active field with true
      let updatedData = tmpArray.map((item) => {
        if (item.active === null) {
          return { ...item, active: true };
        } else if (item.active === undefined) {
          return { ...item, active: true };
        }
        return item;
      });

      //replace all UNDEFINED values for ORDER field with 100.000
      updatedData = tmpArray.map((item) => {
        if (item.order === null || item.order === undefined) {
          return { ...item, order: 100000 };
        }
        return item;
      });

      tmpArray = updatedData;
      console.log('updatedData');
      console.log(updatedData);

      let kontoArr1 = tmpArray.filter((el) => el.lvl == 1);
      kontoArr1 = appUtils.setSortingUtil(
        { field: 'order', order: 'asc' },
        kontoArr1
      );

      let kontoArr2 = tmpArray.filter((el) => el.lvl == 2);

      kontoArr2.sort((a, b) => {
        // Compare primary field
        if (a.guid_parent < b.guid_parent) return -1;
        if (a.guid_parent > b.guid_parent) return 1;

        // Compare secondary field
        return a.order - b.order;
      });

      // kontoArr2 = appUtils.setSortingUtil(
      //   { field: 'order', order: 'asc' },
      //   kontoArr2
      // );

      let kontoArr3 = tmpArray.filter((el) => el.lvl == 3);
      let kontoArr4 = tmpArray.filter((el) => el.lvl == 4);

      //counting sub-categories
      for (var i = 0; i < kontoArr1.length; i++) {
        let counter = 0;
        for (var j = 0; j < kontoArr2.length; j++) {
          if (kontoArr1[i].guid === kontoArr2[j].guid_parent) {
            counter++;
          }
        }
        kontoArr1[i].counter = counter;
        //console.log(kontoArr1[i].description);
        //console.log(counter);
      }

      //counting sub-categories
      for (i = 0; i < kontoArr2.length; i++) {
        let counter = 0;
        for (j = 0; j < kontoArr3.length; j++) {
          if (kontoArr2[i].guid === kontoArr3[j].guid_parent) {
            counter++;
          }
        }
        kontoArr2[i].counter = counter;
      }

      //counting sub-categories
      for (i = 0; i < kontoArr3.length; i++) {
        let counter = 0;
        for (j = 0; j < kontoArr4.length; j++) {
          if (kontoArr3[i].guid === kontoArr4[j].guid_parent) {
            counter++;
          }
        }
        kontoArr3[i].counter = counter;
      }

      this.konto1 = kontoArr1;
      this.konto2 = kontoArr2;
      this.konto3 = kontoArr3;
      this.konto4 = kontoArr4;

      console.log('==============================');
      console.log(this.konto2);
      console.log('==============================');

      // for (let i = 0; i < kontoArr2.length; i++) {
      //   let item = kontoArr1.find((el) => el.id == kontoArr2[i].id_parent);
      //   if (item) {
      //     kontoArr2[i].longdesc =
      //       item.description + '/' + kontoArr2[i].description;
      //   }
      // }

      // for (let i = 0; i < kontoArr3.length; i++) {
      //   let item = kontoArr2.find((el) => el.id == kontoArr3[i].id_parent);
      //   if (item)
      //     kontoArr3[i].longdesc =
      //       item.longdesc + '/' + kontoArr3[i].description;
      // }

      // for (let i = 0; i < kontoArr4.length; i++) {
      //   let item = kontoArr3.find((el) => el.id == kontoArr4[i].id_parent);
      //   if (item)
      //     kontoArr4[i].longdesc =
      //       item.longdesc + '/' + kontoArr4[i].description;
      // }

      for (let i = 0; i < tmpArray.length; i++) {
        if (tmpArray[i].lvl == 1) {
          tmpArray[i].longdesc = tmpArray[i].description;
        } else if (tmpArray[i].lvl == 2) {
          let item = kontoArr2.find((el) => el.id == tmpArray[i].id);
          tmpArray[i].longdesc = item.longdesc;
        } else if (tmpArray[i].lvl == 3) {
          let item = kontoArr3.find((el) => el.id == tmpArray[i].id);
          tmpArray[i].longdesc = item.longdesc;
        } else if (tmpArray[i].lvl == 4) {
          let item = kontoArr4.find((el) => el.id == tmpArray[i].id);
          tmpArray[i].longdesc = item.longdesc;
        }
      }

      this.kontos = tmpArray;
    },
  },
});
