<template>
  <div>
    <!-- {{ modelValue }} -->
    <!-- id="konto1"
      name="selectedKonto1" -->
    <select
      @change="onChange($event, level)"
      class="form-select form-select-sm bg-warning"
      :id="'konto' + props.level"
      :value="modelValue"
    >
      <option :key="0" :value="0"></option>
      <option
        v-for="item in getKonto"
        :key="item.guid"
        :value="item.guid"
        :disabled="item.description.substring(0, 5) == '─────'"
      >
        {{ item.description }}
      </option>
    </select>
  </div>
</template>

<script setup>
import { computed, defineEmits, defineProps } from 'vue';
import appUtils from '../../components/utils/appUtils';

const emit = defineEmits(['onChange']);
const props = defineProps(['modelValue', 'level', 'parentCategory']);

function onChange(eventArgs, level) {
  emit('onChange', eventArgs.target.value, level);
}

const getKonto = computed(function () {
  if (props.level < 1 || props.level > 4) return [];
  const tmp = appUtils.getCategory(props.level);

  //console.log(tmp.filter((el) => el.active == true));

  //all categories
  // if (props.level == 1) {
  //   return tmp;
  // } else if (props.level == 2 || props.level == 3 || props.level == 4) {
  //   if (props.parentCategory) {
  //     if (tmp)
  //       return tmp.filter((el) => el.guid_parent == props.parentCategory);
  //   } else {
  //     return [];
  //   }
  // }

  //all active categories
  if (props.level == 1) {
    return tmp.filter((el) => el.active == true);
  } else if (props.level == 2 || props.level == 3 || props.level == 4) {
    if (props.parentCategory) {
      if (tmp)
        return tmp.filter(
          (el) => el.guid_parent == props.parentCategory && el.active == true
        );
    } else {
      return [];
    }
  }

  return [];
});
</script>
