<template>
  <div class="text-warning">
    <!-- <pre class=""> {{ getKonto11 }}</pre> -->

    <div class="text-warning mt-2 mb-3">
      <h4>Categories</h4>
    </div>

    <base-button
      class="mt-2"
      @click="addNewRec()"
      data-bs-toggle="modal"
      data-bs-target="#modaltransaction"
      >Add <font-awesome-icon icon="fa-solid fa-square-plus"
    /></base-button>

    <base-card>
      <li>
        <base-text-search v-model="searchText"></base-text-search>
      </li>
    </base-card>

    <!-- DIALOG EDIT -->
    <teleport to="body">
      <div
        class="modal fade"
        id="modaltransaction"
        tabindex="-1"
        aria-labelledby="vLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header bg-warning">
              <h5 class="modal-title fs-5 text-dark" id="modaltransactionLabel">
                {{ modalCaption }}
              </h5>

              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div class="modal-body bg-dark text-light">
              <form @submit.prevent="">
                <!-- radio -->
                <!-- Active:{{ activeModal }} Type:{{ typeModal }} -->

                <!-- Active (true|false) -->
                <div class="row mb-3">
                  <label
                    for="radioactive"
                    class="col-sm-3 form-check-label text-end"
                  >
                    <span>&nbsp;&nbsp;&nbsp;Active</span>
                  </label>

                  <div class="col-sm-8 ms-3 form-check form-switch">
                    <input
                      @click="toggleRadio($event, 'active')"
                      class="form-check-input"
                      type="checkbox"
                      id="radioactive"
                      v-model="activeModal"
                    />
                  </div>
                </div>

                <!-- Type (income = true | expence = false) -->
                <div class="row mb-3">
                  <label
                    for="radiotype"
                    class="col-sm-3 form-check-label text-end"
                  >
                    <span>&nbsp;&nbsp;&nbsp;Is income?</span>
                  </label>

                  <div class="col-sm-8 ms-3 form-check form-switch">
                    <input
                      @click="toggleRadio($event, 'type')"
                      class="form-check-input"
                      type="checkbox"
                      id="radiotype"
                      v-model="typeModal"
                    />
                  </div>
                </div>

                <!-- Order -->
                <div class="row mb-3 mt-1">
                  <label for="order" class="col-sm-3 col-form-label text-end"
                    >Order
                  </label>

                  <div class="col-sm-9">
                    <input
                      class="form-control-sm pull-left"
                      type="number"
                      id="order"
                      v-model.trim="orderModal"
                    />
                  </div>
                </div>

                <!-- Description -->
                <div class="row mb-1 mt-4">
                  <label
                    for="description"
                    class="col-sm-3 col-form-label text-end"
                    >Description
                  </label>

                  <div class="col-sm-9">
                    <input
                      class="form-control-sm pull-left"
                      type="text"
                      id="description"
                      v-model.trim="descriptionModal"
                    />
                  </div>
                </div>

                <!-- Order -->
                <!-- <div class="row mb-3 mt-1">
                <label for="order" class="col-sm-3 col-form-label text-end"
                  >Order
                </label>

                <div class="col-sm-9">
                  <input
                    class="form-control-sm pull-left"
                    type="number"
                    id="order"
                    v-model.trim="order"
                  />
                </div>
              </div> -->

                <div class="float-end mb-2">
                  <base-button class="text-end" data-bs-dismiss="modal"
                    >Cancel</base-button
                  >

                  <base-button
                    class="text-end"
                    data-bs-dismiss="modal"
                    data-bs-target="#modaltransaction"
                    :disabled="disableSave()"
                    @click="saveData"
                    >Save <font-awesome-icon icon="fa-floppy-disk"
                  /></base-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </teleport>
    <!-- END DIALOG -->

    <!-- display the categories/subcategories -->
    <section>
      <base-card>
        <table v-if="hasData" class="table table-striped table-dark">
          <thead>
            <tr class="text-warning">
              <th>Edit</th>
              <th>Active</th>
              <th>Description</th>
              <th>Cat2</th>
            </tr>
          </thead>

          <tbody>
            <category-item
              v-for="item in getKonto11"
              :key="item.id"
              :id="item.id"
              :guid="item.guid"
              :description="item.description"
              :lvl="item.lvl"
              :count="item.counter"
              :active="item.active"
              :selectedguid1="getSelectedGuid1"
              :selectedguid2="getSelectedGuid2"
              :selectedguid3="getSelectedGuid3"
              :selectedguid4="getSelectedGuid4"
              @select-id="selectId"
              @select-id-new-child="selectIdNewChild"
              @show-children="showChildren"
            >
              <!-- @change-active="changeActive" -->
              <template #cat2 v-if="item.guid == getSelectedGuid1">
                <table v-if="hasData" class="table table-striped table-dark">
                  <thead>
                    <tr class="text-warning">
                      <th>Edit</th>
                      <th>Active</th>
                      <th>Description</th>
                      <th>Cat3</th>
                    </tr>
                  </thead>

                  <tbody>
                    <category-item
                      v-for="item2 in getKonto22"
                      :key="item2.id"
                      :id="item2.id"
                      :guid="item2.guid"
                      :description="item2.description"
                      :lvl="item2.lvl"
                      :count="item2.counter"
                      :active="item2.active"
                      :selectedguid1="getSelectedGuid1"
                      :selectedguid2="getSelectedGuid2"
                      :selectedguid3="getSelectedGuid3"
                      :selectedguid4="getSelectedGuid4"
                      @select-id="selectId"
                      @select-id-new-child="selectIdNewChild"
                      @show-children="showChildren"
                    >
                      <!-- @change-active="changeActive" -->
                      <template #cat2 v-if="item2.guid == getSelectedGuid2">
                        <table
                          v-if="hasData"
                          class="table table-striped table-dark"
                        >
                          <thead>
                            <tr class="text-warning">
                              <th>Edit</th>
                              <th>Active</th>
                              <th>Description</th>
                              <th>Cat3</th>
                            </tr>
                          </thead>

                          <tbody>
                            <category-item
                              v-for="item3 in getKonto33"
                              :key="item3.id"
                              :id="item3.id"
                              :guid="item3.guid"
                              :description="item3.description"
                              :lvl="item3.lvl"
                              :count="item3.counter"
                              :active="item3.active"
                              :selectedguid1="getSelectedGuid1"
                              :selectedguid2="getSelectedGuid2"
                              :selectedguid3="getSelectedGuid3"
                              :selectedguid4="getSelectedGuid4"
                              @select-id="selectId"
                              @select-id-new-child="selectIdNewChild"
                              @show-children="showChildren"
                            >
                              <!-- @change-active="changeActive" -->
                              <!-- xxxxxxxxxxxxxxxxxx -->

                              <template
                                #cat2
                                v-if="item3.guid == getSelectedGuid3"
                              >
                                <table
                                  v-if="hasData"
                                  class="table table-striped table-dark"
                                >
                                  <thead>
                                    <tr class="text-warning">
                                      <th>Edit</th>
                                      <th>Active</th>
                                      <th>Description</th>
                                      <th>Cat4</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    <category-item
                                      v-for="item4 in getKonto44"
                                      :key="item4.id"
                                      :id="item4.id"
                                      :guid="item4.guid"
                                      :description="item4.description"
                                      :lvl="item4.lvl"
                                      :count="item4.counter"
                                      :active="item4.active"
                                      :selectedguid1="getSelectedGuid1"
                                      :selectedguid2="getSelectedGuid2"
                                      :selectedguid3="getSelectedGuid3"
                                      :selectedguid4="getSelectedGuid4"
                                      @select-id="selectId"
                                      @select-id-new-child="selectIdNewChild"
                                    >
                                      <!-- @change-active="changeActive" -->
                                    </category-item>
                                  </tbody>
                                </table>
                              </template>

                              <!-- xxxxxxxxxxxxxxxxxxxxxxxxxx -->
                            </category-item>
                          </tbody>
                        </table>
                      </template>
                    </category-item>
                  </tbody>
                </table>
              </template>
            </category-item>
          </tbody>
        </table>
        <h3 v-else>No categories found.</h3>
      </base-card>
    </section>
  </div>
</template>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
</style>

<script setup>
import { ref, computed } from 'vue';
import {
  loadFromFirebasePinia,
  saveToFirebaseWithOptions,
} from '../../components/utils/firebaseUtils';
import CategoryItem from '../../components/categories/CategoryItem.vue';
import appUtils from '../../components/utils/appUtils.js';
import { useCategoryStore } from '../../stores/CategoryStore';

//vars for modal
const descriptionModal = ref('');
const selectedguidModal = ref('');
let activeModal = ref(false);
let typeModal = ref(false);
let orderModal = ref(1);
let lvlModal = ref(1);
let guidParentModal = ref('');
let modalCaption = ref('Add category');

const pageStore = useCategoryStore();
const searchText = ref(null);

let selectedGuid1 = ref(null);
let selectedGuid2 = ref(null);
let selectedGuid3 = ref(null);
let selectedGuid4 = ref(null);

function getKontoLevelUtil(lvl) {
  if (lvl === 1) return getKontoLevel(lvl);
  if (lvl === 2) return getKontoLevel(lvl, selectedGuid1.value);
  if (lvl === 3) return getKontoLevel(lvl, selectedGuid2.value);
  if (lvl === 4) return getKontoLevel(lvl, selectedGuid3.value);
}

function getKontoLevel(lvl, parentGuid) {
  let tmp = {};
  if (lvl === 1) {
    tmp = appUtils.getCategory(lvl);
  } else if (lvl >= 2 && lvl <= 4) {
    if (parentGuid) {
      tmp = appUtils
        .getCategory(lvl)
        .filter((el) => el.guid_parent == parentGuid);
    }
  }
  return tmp;
}

function toggleRadio(eventArgs, field) {
  if (field == 'active') {
    activeModal.value = eventArgs.target.checked;
  } else if (field == 'type') {
    typeModal.value = eventArgs.target.checked;
  }
}

function disableSave() {
  return false;
}

function fastFilter(tmp, searchText) {
  const fieldArr = ['description'];

  if (searchText.length <= 1) {
    return tmp.filter(() => {
      return true;
    });
  } else {
    return tmp.filter(
      appUtils.fastFilterGeneral.bind(this, searchText, fieldArr)
    );
  }
}

const getKonto11 = computed(function () {
  let tmp = getKontoLevelUtil(1);
  if (searchText.value) tmp = fastFilter(tmp, searchText.value);
  return tmp;
});

const getKonto22 = computed(function () {
  return getKontoLevelUtil(2);
});

const getKonto33 = computed(function () {
  return getKontoLevelUtil(3);
});

const getKonto44 = computed(function () {
  return getKontoLevelUtil(4);
});

const getSelectedGuid1 = computed(function () {
  if (selectedGuid1.value) {
    return selectedGuid1.value;
  } else {
    return '*';
  }
});

const getSelectedGuid2 = computed(function () {
  if (selectedGuid2.value) {
    return selectedGuid2.value;
  } else {
    return '*';
  }
});

const getSelectedGuid3 = computed(function () {
  if (selectedGuid3.value) {
    return selectedGuid3.value;
  } else {
    return '*';
  }
});

const getSelectedGuid4 = computed(function () {
  if (selectedGuid4.value) {
    return selectedGuid4.value;
  } else {
    return '*';
  }
});

const hasData = computed(function () {
  return useCategoryStore().hasKontos;
});

function saveCommon(saveObj, optionObj) {
  saveObj.type = typeModal.value;
  saveObj.active = activeModal.value;
  saveObj.description = descriptionModal.value; //from modal
  saveObj.order = orderModal.value;

  console.log(optionObj);
  console.log(saveObj);

  // pageStore.loadKontosFirebase;

  saveToFirebaseWithOptions(
    optionObj,
    saveObj,
    pageStore.loadKontosFirebase
  ).then(() => {
    //getCategories();
  });
}

function insertData() {
  console.log('=== insert ===');

  let saveObj = {};
  saveObj.lvl = lvlModal.value;
  if (lvlModal.value > 1) {
    saveObj.guid_parent = guidParentModal.value;
  }

  let optionObj = { url: `konto/{uid}/{newKey}` };
  saveCommon(saveObj, optionObj);
}

function updateData() {
  console.log('=== update ===');

  loadFromFirebasePinia({}, 'konto', null, selectedguidModal.value).then(
    (snapshot) => {
      //we get from firebase original object
      let saveObj = appUtils.getObjectCopy(snapshot.val());
      let optionObj = { url: `konto/{uid}/${selectedguidModal.value}` };
      saveCommon(saveObj, optionObj);
    }
  );
}

function saveData() {
  if (selectedguidModal.value) updateData();
  else insertData();
}

function addNewRec() {
  modalCaption.value = 'Add category';
  selectedguidModal.value = null;
  descriptionModal.value = '';
  activeModal.value = true;
  guidParentModal.value = '';
  lvlModal.value = 1;
}

function showChildren(params) {
  //params[0] => guid
  //params[1] => id
  //params[2] => level
  //params[3] => description
  console.log(params);

  if (params[2] === 1) {
    selectedGuid1.value = params[0];
    selectedGuid2.value = null;
    selectedGuid3.value = null;
    selectedGuid4.value = null;
  } else if (params[2] === 2) {
    selectedGuid2.value = params[0];
    selectedGuid3.value = null;
    selectedGuid4.value = null;
  } else if (params[2] === 3) {
    selectedGuid3.value = params[0];
    selectedGuid4.value = null;
  } else if (params[2] === 4) {
    selectedGuid4.value = params[0];
  }
}

function selectId(params) {
  //params[0] => guid
  //params[1] => id
  //params[2] => level
  //params[3] => description
  console.log(params);

  let data = null;
  if (params[2] >= 1 && params[2] <= 4) {
    data = getKontoLevelUtil(params[2]);
  } else return;

  console.log(data);
  modalCaption.value = 'Edit category [' + params[0] + ']';

  selectedguidModal.value = params[0];
  descriptionModal.value = params[3];

  for (var i = 0; i < data.length; i++) {
    if (data[i].guid === params[0]) {
      typeModal.value = data[i].type;
      activeModal.value = data[i].active;

      if (data[i].order) {
        orderModal.value = data[i].order;
      } else orderModal.value = 0;

      guidParentModal.value = data[i].guid_parent;
      break;
    }
  }
}

function selectIdNewChild(params) {
  //params[0] => guid
  //params[1] => id
  //params[2] => level
  //params[3] => description
  // console.log(`selectIdNewChild => params: ${params}`);
  modalCaption.value =
    'Add sub-category to ' +
    params[3] +
    ' [to level ' +
    (parseInt(params[2]) + 1) +
    ']';

  selectedguidModal.value = null;
  guidParentModal.value = params[0];
  descriptionModal.value = '';
  activeModal.value = true;
  typeModal.value = false;
  orderModal.value = 0;
  lvlModal.value = params[2] + 1;
}
</script>
